<template>
	<div class="depart-remind-container">
		<div class="content-area">
			<div class="title-content"></div>
			<div class="table-content">
				<div class="data-list">
					<div class="table-list">
						<div class="t-head">
							<span class="per-1 th-1">线路</span>
							<span class="per-2">车牌号</span>
							<span class="per-3">起始站</span>
							<span class="per-4">终点站</span>
							<span class="per-5">司机</span>
							<span class="per-6">发车时间</span>
						</div>
						<div class="data-for">
							<vue-seamless-scroll
								ref="seamlessScroll"
								v-if="dataList.length"
								:data="dataList"
								class="warp"
								:class-option="classOption"
							>
								<div class="t-body">
									<div class="data-item" v-for="(item, index) in dataList" :key="index">
										<!-- eslint-disable-next-line -->
										<!-- <div class="per-1">
											{{ index < 9 ? "0" + (index + 1) : index + 1 }}
										</div> -->
										<div class="per-1 line-name">
											<span class="icon"></span>
											<span>
												{{ item.routeNa }}
											</span>
										</div>
										<div class="per-2">
											{{ item.vehicleNo }}
										</div>
										<div class="per-3">
											{{ item.startSiteNa }}
										</div>
										<div class="per-4">
											{{ item.endSiteNa }}
										</div>
										<div class="per-5">
											{{ item.driverNa }}
										</div>
										<div class="per-6">
											{{ item.planTime }}
										</div>
									</div>
								</div>
							</vue-seamless-scroll>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
// import dayjs from "dayjs";

export default {
	components: {
		vueSeamlessScroll
	},
	data() {
		return {
			dataList: [],
			classOption: {
				singleHeight: 79,
				limitMoveNum: 11,
				hoverStop: false,
				step: 2
			},
			timer: null,
			goTypeMap: {
				1: "上行",
				2: "下行"
			}
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			this.clearInter();
			const url = "/crrc-palm-bus/realtimeOperation/findWaitDepartureWaybill";
			const params = {};
			const res = await this.$http.post(url, params, { baseType: "base" });
			const data = res || [];
			// 未发车的数据 按发车时间升序
			this.dataList = data;
			await this.$nextTick();
			this.$refs.seamlessScroll.reset();
			this.setInter();
		},
		setInter() {
			this.clearInter();
			this.timer = setInterval(
				() => {
					this.init();
					console.log(1111);
				},
				5 * 60 * 1000
			);
		},
		clearInter() {
			this.timer && clearInterval(this.timer);
			this.timer = null;
		}
	}
};
</script>

<style lang="scss" scoped>
.depart-remind-container {
	width: 1920px;
	height: 1080px;
	background-color: #0d1d40;
	background-image: url("~@/assets/departRemind/content_bg.png");
	background-repeat: no-repeat;
	background-size: 1966px 1146px;
	background-position: center;
	.content-area {
		width: 100%;
		height: 100%;
		// background: #091940;
		border-radius: 4px;
		padding: 42px 88px 62px;
		.title-content {
			width: 100%;
			height: 78px;
			background: url("~@/assets/departRemind/title_bg.png") no-repeat;
			background-size: 100% 100%;
			margin-bottom: 28px;
		}
		.table-content {
			padding: 0 15px;
			.table-list {
				width: 100%;
				color: #fff;
				.t-head {
					display: flex;
					align-items: center;
					line-height: 70px;
					font-size: 28px;
					font-weight: 600;
					// background: #2d4b82;
					// text-align: center;
					height: 70px;
					background: #0058b0;
					border-radius: 4px;
					border: 2px solid;
					border-image: linear-gradient(270deg, rgba(51, 123, 178, 0.5), rgba(51, 123, 178, 0.5)) 2 2;
					.th-1 {
						padding-left: 80px;
					}
				}
				.per-1 {
					width: 15%;
					// text-align: center;
				}
				.per-2 {
					width: 15%;
					padding-left: 10px;
				}
				.per-3 {
					width: 20%;
					padding-left: 10px;
				}
				.per-4 {
					width: 20%;
					padding-left: 10px;
				}
				.per-5 {
					width: 20%;
					padding-left: 10px;
				}
				.per-6 {
					width: 10%;
					padding-left: 10px;
				}
				.data-for {
					height: 790px;
					padding: 15px 0;
				}
				.warp {
					width: 100%;
					height: 790px;
					overflow: hidden;
				}
				.data-item {
					font-size: 26px;
					display: flex;
					height: 64px;
					line-height: 64px;
					align-items: center;
					background: rgba(0, 88, 176, 0.45);
					// background: #152449;
					// border-top: 1px solid #173c71;
					// text-align: center;
					margin-bottom: 15px;
					border-radius: 4px;
					border: 2px solid;
					border-image: linear-gradient(270deg, rgba(51, 123, 178, 0.5), rgba(51, 123, 178, 0.5)) 2 2;
					&:last-child {
						margin-bottom: 0;
					}
					.line-name {
						display: flex;
						align-items: center;
						// justify-content: center;
						color: #08ffff;
						padding-left: 40px;
						.icon {
							width: 24px;
							height: 24px;
							background: url("~@/assets/departRemind/line_icon.png") no-repeat;
							background-size: 100% 100%;
							margin-right: 16px;
						}
					}
				}
			}
		}
	}
}
</style>
